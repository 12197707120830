import { UserRole } from '@shared/types';
import { Select } from 'antd';
import React from 'react';

export const AdminSelectRole: React.FC<{
  value?: UserRole;
  onChange?: (newValue: UserRole) => void;
  disabled?: boolean;
}> = ({ value, onChange, disabled }) => {
  return (
    <Select disabled={disabled} value={value} onChange={onChange}>
      <Select.Option value={UserRole.ENROLLED}>Employee</Select.Option>
      <Select.Option value={UserRole.ADMIN}>Basic Admin</Select.Option>
      <Select.Option value={UserRole.HR_ADMIN}>Full Admin</Select.Option>
    </Select>
  );
};
